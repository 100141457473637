<template>
  <div class="search_page">
    <!-- 顶部分类 -->
    <div class="search_header">
      <div class="callback" @click="goBack"><IconArrow /><span class="font_word">返回 </span></div>
      <ul class="total_level1">
        <li
          v-for="(item, index) in searchList" :key="item[catId]" class="level_item"
          :class="{active: active === index}" @click="selectActive(item, index)">{{ `${item.cat_name}(${item.total})` }}</li>
      </ul>
    </div>
    <!-- 内容 -->
    <div class="search_content">
      <vueScroll v-show="lastMatList.length" ref="vs" @handle-scroll="handleScroll">
        <div>
          <!-- 我的列表页id重复，直接切换到分类页时，my列表数据还在，id键值重复报错，暂时用index代替 -->
          <ModelItem
            v-for="item in lastMatList" :key="item.id" ref="modelItem" :tabid="searchList[active] ? searchList[active][catId] : 0"
            class="model_item" from="searchpage" :item="modelItem(item)" />
        </div>
      </vueScroll>

      <!-- 无内容 -->
      <div class="noData_box">
        <p class="font">没有找到相关内容，换个词试试</p>
        <div class="recommnend_box">
          <h3 class="recommend_title">热搜推荐：</h3>
          <ul class="recommend_list">
            <li
              v-for="(item, index) in search.recommendList" :key="index" class="recommend_item"
              @click="pushTarget(item)">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 分类 -->
    <div class="bottom_pagination">
      <Pagination :total="pageTotal" :defaultActive="matListParams[Page]" @selectIndex="selectIndex" />
    </div>
  </div>
</template>

<script>
import IconArrow from '@/components/Icons/IconArrow.vue'

import ModelItem from '@/components/ModelItem/index.vue'
import Pagination from '@/components/Pagination/index.vue'

import {
  scrollRateFast, isCollect, Scroll, modelStatus, Cat1,
  Sort, Page, Keyword, pageSize, MatId, myIndex, modelItemHeight
} from '@/common/const.js'
import { strictExtend } from '@shared/utils/base/index.js'
import { report } from '@/report/index.js'

const pageDefault = 1
const detail = 'detail'
const catId = 'cat_id'
const params = { [Cat1]: 0, [Sort]: 1, [Page]: pageDefault, [Keyword]: '', size: pageSize }

export default {
  name: 'SearchPage',

  components: { IconArrow, ModelItem, Pagination },

  data () {
    return {
      Page, Keyword, catId,
      matListParams: strictExtend(params)
    }
  },

  computed: {
    search () {
      return this.$store.state.search
    },

    active () {
      return this.search.active
    },

    level1Index () {
      return this.$store.state.categoryActive
    },

    level1List () {
      return this.$store.state.operational.categoryArr
    },

    matList () {
      return this.$store.state.matList
    },

    matTotalCount () {
      return this.$store.state.matTotalCount
    },

    pageTotal () {
      return this.searchList[this.active]?.total || 0
    },

    searchList () {
      return this.$store.state.searchList
    },

    downedList () {
      return this.$store.state.downedList
    },

    downingList () {
      return this.$store.state.downingList
    },

    collectIdList () {
      return this.$store.state.collectIdList
    },

    lastMatList () {
      return this.matList.map(item => {
        const id = item.id + ''

        let type = this.downedList.includes(id) ? modelStatus.downed : modelStatus.down
        type = this.downingList.includes(id) ? modelStatus.downing : type

        const collectActive = this.collectIdList.includes(item.id)
        return {
          ...item,
          type,
          [isCollect]: collectActive
        }
      })
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.initParamAndActive()
    next()
  },

  created () {
    if (this.$route.query.from === detail) { // 详情页返回
      this.initState()
    } else { // url直接进入项目
      this.initParamAndActive()
    }
  },

  methods: {
    initState () {
      this.matListParams[Cat1] = this.searchList?.[this.search.active]?.[catId] || 0
      this.matListParams[Keyword] = this.search.searchVal
      this.matListParams[Page] = this.search[Page]
      this.$nextTick(() => {
        this.$refs.vs.scrollTo({ y: this.search[Scroll] }, scrollRateFast)
      })

      this.getSearchList()
    },

    initParamAndActive () {
      this.matListParams[Cat1] = 0 // 默认传0
      this.matListParams[Keyword] = this.search.searchVal
      this.matListParams[Page] = pageDefault

      this.$store.commit('changeSearch', { active: 0 })
      this.getSearchList()
    },

    getSearchList () {
      this.$store.dispatch('getSearchMat', this.matListParams).then(() => {
        const listLen = this.matTotalCount
        // 展示上报
        listLen && this.reportSearchpageView()
        !listLen && this.reportSearchpageView(3)
        // 模板展示上报
        this.$nextTick(() => {
          this._domArr = this.$refs.modelItem
          this.domScrollViewReportHandle()
        })
      })
    },

    selectActive (item, index) {
      this.matListParams[Cat1] = item[catId]
      this.matListParams[Page] = pageDefault

      this.$store.commit('changeSearch', { active: index, [Page]: pageDefault })
      this.getSearchList()
    },

    selectIndex (index) {
      this.reportSearchpage(6)

      this.matListParams[Page] = index
      this.$store.commit('changeSearch', { [Page]: index, [Scroll]: 0 })

      this.$refs.vs.scrollTo({ y: 0 }, scrollRateFast)
      this.getSearchList()
    },

    reportSearchpageView (type = 1) {
      const id = this.searchList.length ? this.searchList[this.active][catId] : ''
      report('searchpage', { actiontype: type, word: this.search.searchVal, defaulttab: id, amount: this.matTotalCount, clickid: '', free: '', goodesid: '', goodstype: '', classid: '', ext: '' })
    },

    reportSearchpage (clickid) {
      const id = this.searchList.length ? this.searchList[this.active][catId] : ''
      report('searchpage', { actiontype: 2, word: this.search.searchVal, defaulttab: id, amount: '', clickid, free: '', goodesid: '', goodstype: id, classid: '', ext: '' })
    },

    goBack () {
      this.reportSearchpage(7)

      this.$router.push(`/${this.level1Index === myIndex ? 'my' : 'classify'}?from=search`)
    },

    modelItem (item) {
      return {
        id: item.id,
        type: item.type,
        [isCollect]: item[isCollect],
        pay_type: item.pay_type,
        title: item.name,
        class_id: item.class_id,
        img: item.small_img,
        url: `/detail?from=search&${MatId}=${item.id}`
      }
    },

    getQueryKeyword () {
      return decodeURIComponent(this.$route.query[Keyword] || '')
    },

    pushTarget (val) {
      if (val === this.getQueryKeyword()) { return }

      const targetVal = val || this.val

      report('search', { posid: 2, word: this.val, ext: '' })

      this.$store.commit('changeSearch', { searchVal: targetVal })
      this.$router.push(`/search?${Keyword}=${encodeURIComponent(targetVal)}`) // 提供给searchPage检测
    },

    handleScroll (v) { // 记录滚动的位置, handle-scroll-complete不生效，到时候可以优化
      if (this._timer) { clearTimeout(this._timer) }
      this._timer = setTimeout(() => {
        clearTimeout(this._timer)
        this.$store.commit('changeSearch', { [Scroll]: v.scrollTop })
        this.domScrollViewReportHandle()
      }, 500)
    },

    domScrollViewReportHandle () { // 不能使用window.scroll统一处理，使用了第三方组件阻断了scroll事件，只为上报
      if (!this._domArr?.length) { return }
      const scroll = this.search[Scroll]
      const parentHeight = this.$refs.vs.$el.offsetHeight

      const arr = []
      this._domArr.forEach((dom, index) => {
        const offsetTop = dom.$el.offsetTop
        if (scroll + parentHeight - modelItemHeight / 2 > offsetTop) { // 已滚动高度 + 滚动盒子高度 - 2分之1模板item高度 > 当前模板item的offsetTop
          arr.push(index)
          // 上报
          report('goodsshow', { goodsid: dom.item.id, calssid: dom.item.class_id, goodstype: '', ver: '1', ext: this.searchList?.[this.active]?.[catId] || '' })
        }
      })

      arr.reverse().forEach(index => {
        this._domArr.splice(index, 1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search_page { display: flex; flex-direction: column; height: 100%;
  .search_header { position: relative; height: 40px; padding: 0 16px; font-size: 12px; background-color: #fff; border-bottom: 1px solid #DEDEDE;

    .callback { position: absolute; left: 0; margin-left: 16px; font-size: 14px; line-height: 40px; cursor: pointer;
      .font_word { margin-left: 8px; font-weight: 400; color: #666; vertical-align: middle; }
    }

    .total_level1 { display: flex; justify-content: center; height: 40px; padding: 0 16px; font-size: 12px; color: rgba(68, 68, 68, 0.6); background-color: #fff; }
    .level_item { position: relative; display: flex; align-items: center; height: 100%; cursor: pointer;
      &:hover { color: rgba(68, 68, 68, 1); }
      &.active { font-weight: bold; color: rgba(68, 68, 68, 1);
        &::after { position: absolute; bottom: 0; left: 50%; width: 90%; height: 2px; content: ''; background: rgba(68, 68, 68, 1); transform: translateX(-50%); }
      }
      &+.level_item { margin-left: 20px  ; }
    }
  }

  .search_content { display: flex; flex: 1; flex-wrap: wrap; height: calc(100% - 77px); padding: 4px 4px; padding-bottom: 20px; overflow: hidden;
    &.level2 { height: calc(100% - 117px); }
    .model_item { margin: 8px 10px; }

    .noData_box { width: 100%; text-align: center; background: url(~@/assets/images/bg_search_noData.png) center 78px; background-repeat: no-repeat; background-size: 344px 298px;
      .font { margin: 290px 0 64px 0; font-size: 18px; font-weight: 400; color: #B1C1EA; }
      .recommnend_box { display: flex; justify-content: center; width: 344px; margin: auto; font-size: 0; }
      .recommend_title { font-size: 16px; font-weight: 400; color: #444; }

      .recommend_list { display: flex; flex: 1; flex-wrap: wrap; font-size: 16px; color: #3B75FE; }
      .recommend_item { margin: 0 0 16px 21px; cursor: pointer; }
    }
  }

  .bottom_pagination { display: flex; align-items: center; justify-content: center; height: 36px; }
}
</style>
